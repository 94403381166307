import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Navbar from "../components/Navbar/Navbar";
import { Container } from 'react-bootstrap';
import PostListing from "../components/PostListing/PostListing";

class Article extends Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <Helmet title={`Article | ${config.siteTitle}`} />
        <Navbar active="article"/>
        <Container className="justify-content-center">
            <PostListing postEdges={postEdges} />
        </Container>
      </Layout>
    );
  }
}

export default Article;

export const pageQuery = graphql`
  query ArticleQuery {
    allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;